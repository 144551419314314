/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useRouteMatch } from 'react-router-dom';
import { logout, saveUser, setSessionStartedAt } from '../../actions';
import mvbLogo from '../../assets/images/ico.png';
import { sessionExpired } from '../../utils/sessionExpired';
import { addUserEvent } from '../../api-routes';

const Navbar = (props) => {
  useEffect(() => {
    const addSessionUserEvent = async () => {
      const sessionStartedAt = await localStorage.getItem('sessionStartedAt');

      if (sessionExpired(sessionStartedAt, 10)) {
        const newSessionStart = new Date().getTime();
        props.setSessionStartedAt(newSessionStart);
        localStorage.setItem('sessionStartedAt', newSessionStart);

        await addUserEvent({
          type: 'session',
          properties: {
            device: 'web',
          },
        });
      }
    };

    addSessionUserEvent();
  }, []);

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };

  let navHeader = null;
  if (props.navHeader) {
    navHeader = <h1 className="navbar-page-header">{props.navHeader}</h1>;
  }

  let menuButtons = (
    <>
      <button
        className="navbar-toggler navbar-toggler align-self-center"
        type="button"
        onClick={() => document.body.classList.toggle('sidebar-icon-only')}
      >
        <i className="mdi mdi-menu mr-2"></i>
      </button>
      <button
        className="navbar-toggler navbar-toggler-left d-lg-none align-self-center"
        type="button"
        onClick={toggleOffcanvas}
      >
        <span className="mdi mdi-menu"></span>
      </button>
    </>
  );

  const handleBenefitsBackPress = () => {
    const search = props.location.search;
    const activePage = new URLSearchParams(search).get('activePage');
    const adminPage = new URLSearchParams(search).get('adminPage');
    const editorStateAgencyPage = new URLSearchParams(search).get(
      'editorStateAgencyPage'
    );
    const paginationPage = new URLSearchParams(search).get('page');
    const searchKeywords = new URLSearchParams(search).get('keywords');
    const filterCategories = new URLSearchParams(search).get('categories');
    const filterDependents = new URLSearchParams(search).get('dependents');
    const filterStates = new URLSearchParams(search).get('states');
    let filterGov = new URLSearchParams(search).get('govFilter');

    filterGov = filterGov !== 'null' ? filterGov : '';
    let url = `/benefits/by-type/${activePage}?categories=${filterCategories}&govFilter=${filterGov}&dependents=${filterDependents}&states=${filterStates}`;
    if (adminPage) {
      url = `/benefits?activePage=benefits`;
    }

    if (editorStateAgencyPage) {
      url = `/state-agency`;
    }

    if (searchKeywords) {
      props.history.push(
        `${url}&page=${paginationPage}&keywords=${searchKeywords}`
      );
    } else {
      props.history.push(`${url}&page=${paginationPage}`);
    }
  };

  let saveBenefit;

  if (useRouteMatch({ path: '/benefits/:id', exact: true })) {
    menuButtons = (
      <button className="back-button" onClick={() => handleBenefitsBackPress()}>
        <i className="fa fa-angle-left"></i> Back
      </button>
    );
    saveBenefit = props.saveBenefit;
  }

  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a
          className="navbar-brand brand-logo-mini align-self-center d-lg-none"
          href="!#"
          onClick={(evt) => evt.preventDefault()}
        >
          <img src={mvbLogo} alt="logo" />
        </a>
        <div className="navbar-menu-items">
          {menuButtons}

          {navHeader}

          {saveBenefit}
        </div>
        {/* <ul className="navbar-nav navbar-nav-right"></ul> */}
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.user,
  navHeader: state.session.navHeader,
  saveBenefit: state.session.saveBenefit,
  sessionStartedAt: state.session.sessionStartedAt,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
  saveUser: (a) => {
    dispatch(saveUser(a));
  },
  setSessionStartedAt: (sessionStartedAt) => {
    dispatch(setSessionStartedAt(sessionStartedAt));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));

import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../app/shared/Spinner';
import { history } from '..';
import { setNavHeader } from '../actions/session';
// ADMIN
const BenefitsAdmin = lazy(() => import('./admin-pages/Benefits/Benefits'));
const AdminsBenefitsDetails = lazy(() =>
  import('./admin-pages/Benefits/BenefitsDetails')
);
const AddEditBenefits = lazy(() =>
  import('./admin-pages/Benefits/AddEditBenefits')
);
const AdminEditBenefit = lazy(() =>
  import('./admin-pages/Benefits/EditBenefit')
);
const AddEditBenefitCategory = lazy(() =>
  import('./admin-pages/Benefits/AddEditBenefitCategory')
);
const ResourcesAdmin = lazy(() => import('./admin-pages/Resources/Resources'));
const AddEditResource = lazy(() =>
  import('./admin-pages/Resources/AddEditResource')
);
const AddResourceItem = lazy(() =>
  import('./admin-pages/Resources/AddResourceItem')
);
const BenefitsOnResourceItem = lazy(() =>
  import('./admin-pages/Resources/BenefitsOnResourceItem')
);

const EditResourceItem = lazy(() =>
  import('./admin-pages/Resources/EditResourceItem')
);

const ResourceItemAdmin = lazy(() =>
  import('./admin-pages/Resources/ResourceItem')
);
const Users = lazy(() => import('./admin-pages/Users/Users'));
const Referrals = lazy(() => import('./admin-pages/Referrals/Referrals'));
const UserDetails = lazy(() => import('./admin-pages/Users/UserDetails'));
const FaqAdmin = lazy(() => import('./admin-pages/Faq/Faq'));
const AdminsPasswordEdit = lazy(() =>
  import('./admin-pages/Users/PasswordEdit')
);

const AdminsServiceBackgroundEdit = lazy(() =>
  import('./admin-pages/Users/ServiceBackgroundEdit')
);
const AdminsServiceRecognitionEdit = lazy(() =>
  import('./admin-pages/Users/ServiceRecognitionEdit')
);
const AdminsDisabilityInfoEdit = lazy(() =>
  import('./admin-pages/Users/DisabilityInfoEdit')
);
const AdminsAdditionalInfoEdit = lazy(() =>
  import('./admin-pages/Users/AdditionalInfoEdit')
);
const AdminsUserEvents = lazy(() => import('./admin-pages/Users/UserEvents'));

const AdminsUserEventsByUser = lazy(() =>
  import('./admin-pages/Users/UserEventsByUser')
);

const AdminsFeedback = lazy(() => import('./admin-pages/Feedback/Feedback'));

const Statistics = lazy(() => import('./admin-pages/Statistics/Statistics'));

// MEMBERS
const Benefits = lazy(() => import('./member-pages/Benefits/Benefits'));
const BenefitsDetails = lazy(() =>
  import('./member-pages/Benefits/BenefitsDetails')
);
const Feedback = lazy(() => import('./member-pages/Feedback/Feedback'));
const ResourcesUser = lazy(() =>
  import('./member-pages/Resources/Resourcesuser')
);

const SavedResourceItems = lazy(() =>
  import('./member-pages/Resources/SavedResourceItems')
);

const ResourceItem = lazy(() =>
  import('./member-pages/Resources/ResourceItem')
);

const EditUserPassword = lazy(() =>
  import('./member-pages/Profile/PasswordEdit')
);

const EditUserDetails = lazy(() =>
  import('./member-pages/Profile/DetailsEdit')
);

const UserProfile = lazy(() => import('./member-pages/Profile/Profile'));
const EditAdditionalInfo = lazy(() =>
  import('./member-pages/Profile/AdditionalInfoEdit')
);
const EditDisabilityInfo = lazy(() =>
  import('./member-pages/Profile/DisabilityInfoEdit')
);
const EditServiceBackground = lazy(() =>
  import('./member-pages/Profile/ServiceBackgroundEdit')
);
const EditServiceRecognition = lazy(() =>
  import('./member-pages/Profile/ServiceRecognitionEdit')
);

const OptInForm = lazy(() => import('./member-pages/Profile/OptInForm'));
const AdditionalInformation = lazy(() =>
  import('./member-pages/Profile/OptInForm/AdditionalInformation')
);

const ProfileCompletion = lazy(() =>
  import('./member-pages/Profile/CompletionWizard')
);

// EDITORS
const BenefitsEditor = lazy(() => import('./editor-pages/Benefits/Benefits'));
const AddBenefitEditor = lazy(() =>
  import('./editor-pages/Benefits/AddBenefit')
);
const EditBenefitEditor = lazy(() =>
  import('./editor-pages/Benefits/EditBenefit')
);
const StateAgencyEditor = lazy(() =>
  import('./editor-pages/StateAgency/StateAgency')
);

// LEAD PARTNERS
const ReferralsLeadPartner = lazy(() =>
  import('./lead-partner-pages/Referrals/Referrals')
);

// UNAUTHENTICATED
const Login = lazy(() => import('./user-pages/Login'));
const Register = lazy(() => import('./user-pages/Register'));
const EmailVerification = lazy(() => import('./user-pages/EmailVerification'));
const ChangePassword = lazy(() => import('./user-pages/ChangePassword'));
const ResertPassword = lazy(() => import('./user-pages/ResertPassword'));
const PasswordResetSuccess = lazy(() =>
  import('./user-pages/PasswordResetSuccess')
);
const Faq = lazy(() => import('./member-pages/Faq/Faq'));
const InfoPage = lazy(() => import('./info-page/InfoPage'));
const Support = lazy(() => import('./user-pages/Support'));
const ContactRequest = lazy(() => import('./user-pages/ContactRequest'));

const pages = [
  {
    path: '/about-us',
    component: InfoPage,
  },
  {
    path: '/privacy-policy',
    component: InfoPage,
  },
  {
    path: '/terms-of-use',
    component: InfoPage,
  },
  {
    path: '/support',
    component: Support,
  },
];

const AppRoutes = (props) => {
  function commonRoutes() {
    return pages.map((page) => (
      <Route
        exact
        key={page.path}
        path={page.path}
        component={page.component}
      />
    ));
  }

  function protectedRouteMember() {
    return (
      <>
        <Switch>
          <Route exact path="/benefits/by-type/:type" component={Benefits} />
          <Route exact path="/benefits" component={Benefits} />
          <Route exact path="/benefits/:id" component={BenefitsDetails} />
          <Route
            exact
            path="/resources/:name"
            render={(props) => (
              <ResourcesUser key={props.match.params.name} {...props} />
            )}
          />

          <Route exact path="/saved-resources" component={SavedResourceItems} />
          <Route exact path="/resources/items/:id" component={ResourceItem} />
          <Route
            exact
            path="/profile/completion/:step"
            component={ProfileCompletion}
          />
          <Route exact path="/profile/password" component={EditUserPassword} />
          <Route exact path="/profile/edit" component={EditUserDetails} />
          <Route
            exact
            path="/profile/additional-info"
            component={EditAdditionalInfo}
          />
          <Route
            exact
            path="/profile/disability-info"
            component={EditDisabilityInfo}
          />
          <Route
            exact
            path="/profile/service-background"
            component={EditServiceBackground}
          />
          <Route
            exact
            path="/profile/service-recognition"
            component={EditServiceRecognition}
          />

          <Route exact path="/connect-with" component={OptInForm} />
          <Route
            exact
            path="/connect-with-contact-info"
            component={AdditionalInformation}
          />
          <Route exact path="/profile" component={UserProfile} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/faq" component={Faq} />
          {commonRoutes()}
          {/*
        share with friends
      */}
          <Redirect
            to={
              history.location.pathname === '/benefits'
                ? `${history.location.pathname}${history.location.search}`
                : '/benefits'
            }
          />
        </Switch>
      </>
    );
  }

  function protectedRouteAdmin() {
    return (
      <Switch>
        <Route exact path="/benefits" component={BenefitsAdmin} />
        <Route exact path="/benefits/:id" component={AdminsBenefitsDetails} />
        <Route exact path="/add-benefit" component={AddEditBenefits} />
        <Route exact path="/edit-benefit/:id" component={AdminEditBenefit} />
        <Route
          exact
          path="/add-benefit-category"
          component={AddEditBenefitCategory}
        />
        <Route
          exact
          path="/edit-benefit-category"
          component={AddEditBenefitCategory}
        />
        <Route exact path="/resources" component={ResourcesAdmin} />
        <Route exact path="/add-resource" component={AddEditResource} />
        <Route exact path="/edit-resource" component={AddEditResource} />
        <Route exact path="/resources/:name" component={ResourceItemAdmin} />
        <Route exact path="/add-resource-item" component={AddResourceItem} />
        <Route
          exact
          path="/items/:id/benefits"
          component={BenefitsOnResourceItem}
        />
        <Route exact path="/edit-resource-item" component={EditResourceItem} />
        <Route exact path="/admins/users" component={Users} />
        <Route exact path="/admins/users/:id" component={UserDetails} />
        <Route exact path="/admins/user-events" component={AdminsUserEvents} />
        <Route
          exact
          path="/admins/user-events/:id"
          component={AdminsUserEventsByUser}
        />
        <Route
          exact
          path="/admins/users/:id/password"
          component={AdminsPasswordEdit}
        />
        <Route
          exact
          path="/admins/users/:id/service-background"
          component={AdminsServiceBackgroundEdit}
        />
        <Route
          exact
          path="/admins/users/:id/service-recognition"
          component={AdminsServiceRecognitionEdit}
        />
        <Route
          exact
          path="/admins/users/:id/disability-info"
          component={AdminsDisabilityInfoEdit}
        />
        <Route
          exact
          path="/admins/users/:id/additional-info"
          component={AdminsAdditionalInfoEdit}
        />
        <Route exact path="/admins/referrals" component={Referrals} />

        <Route exact path="/feedback" component={AdminsFeedback} />
        <Route exact path="/faq" component={FaqAdmin} />
        <Route exact path="/admins/statistics" component={Statistics} />
        {commonRoutes()}

        <Redirect to="/benefits" />
      </Switch>
    );
  }

  function protectedRouteEditor() {
    return (
      <Switch>
        <Route exact path="/benefits" component={BenefitsEditor} />
        <Route exact path="/benefits/new" component={AddBenefitEditor} />
        <Route exact path="/benefits/edit/:id" component={EditBenefitEditor} />
        <Route exact path="/benefits/:id" component={BenefitsDetails} />
        <Route exact path="/state-agency" component={StateAgencyEditor} />
        {commonRoutes()}

        <Redirect to="/state-agency" />
      </Switch>
    );
  }

  function protectedRouteLeadPartner() {
    return (
      <Switch>
        <Route exact path="/referrals" component={ReferralsLeadPartner} />
        {commonRoutes()}

        <Redirect to="/referrals" />
      </Switch>
    );
  }

  useLocation();
  useEffect(() => {
    props.setNavHeader(null);
  });
  return (
    <>
      <Suspense fallback={<Spinner />}>
        {props.user !== null ? (
          props.isEditor ? (
            protectedRouteEditor()
          ) : props.isAdmin ? (
            protectedRouteAdmin()
          ) : props.isLeadPartner ? (
            protectedRouteLeadPartner()
          ) : (
            protectedRouteMember()
          )
        ) : (
          <>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/email-verification" component={EmailVerification} />
              <Route path="/reset-password/:id" component={ResertPassword} />
              <Route
                path="/reset-password-success"
                component={PasswordResetSuccess}
              />
              <Route path="/change-password" component={ChangePassword} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/feedback" component={ContactRequest} />
              {commonRoutes()}
              <Redirect to="/register" />
            </Switch>
          </>
        )}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.session.user,
  isAdmin: state.session.isAdmin,
  isEditor: state.session.isEditor,
  isLeadPartner: state.session.isLeadPartner,
});

const mapDispatchToProps = (dispatch) => ({
  setNavHeader: (header) => {
    dispatch(setNavHeader(header));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
